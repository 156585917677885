import React from 'react';
import { useTranslation } from 'react-i18next';

// helpers
import { getFormattedDate } from 'helpers/date';
import { getPriceFromCents } from 'helpers/prices';
import { replaceUnderscoreToDash } from 'helpers/utils';

// constants
import { DATE_FORMAT } from '../constants';

// components
import { Text } from 'wikr-core-components';
import StatusIcon from './StatusIcon';
import PriceRow from './PriceRow';

// styled
import * as S from './styled';

// types
import { IUpcomingProductProps } from '../types';

const UpcomingProduct = ({ isTabExpanded, upcomingProduct }: IUpcomingProductProps) => {
    const { t } = useTranslation();

    const autoRenewalDate = getFormattedDate(upcomingProduct.started_from, DATE_FORMAT);

    return (
        <S.GeneralInfo mb={0} mt={24}>
            <S.Status>
                <StatusIcon isActive={false} />
                <Text type="caption" color="text-secondary" text={t('subscription.status.upcoming')} />
                <Text type="caption" color="text-secondary" text=":" />
            </S.Status>
            <PriceRow
                price={`${getPriceFromCents(upcomingProduct.amount)} ${upcomingProduct.currency}`}
                period={upcomingProduct.subscription_period}
                subscriptionType={t('subscription.type.subscription')}
            />
            {isTabExpanded && (
                <S.DateInfo>
                    <Text type="caption" color="text-secondary" text={t('subscription.text.autoRenewalOn')} />
                    <Text type="caption">{replaceUnderscoreToDash(autoRenewalDate)}</Text>
                </S.DateInfo>
            )}
        </S.GeneralInfo>
    );
};

export default UpcomingProduct;
