import { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// components
import SidebarLinkButton from './SidebarLinkButton';

// types
import { linkType, SidebarLinkProps } from '../types';

const SidebarLink = ({
    to,
    title,
    onClick,
    dataLocator,
    type,
    target = '_self',
    external = false,
}: SidebarLinkProps) => {
    const handleClick = () => onClick && onClick(to);
    const { pathname } = useLocation();

    const isActive = pathname === to || pathname.startsWith(`${to}/`);
    const isPrimary = linkType.Primary === type;

    const commonLinkProps = {
        'data-locator': dataLocator,
        onClick: handleClick,
        target,
    };

    if (external) {
        return (
            <a href={to} {...commonLinkProps}>
                <SidebarLinkButton isActive={isActive} isPrimary={isPrimary} title={title} />
            </a>
        );
    }

    return (
        <NavLink to={to} {...commonLinkProps}>
            <SidebarLinkButton isActive={isActive} isPrimary={isPrimary} title={title} />
        </NavLink>
    );
};

export default memo(SidebarLink);
