import styled, { css } from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const Burger = styled.button<{ isActive: boolean }>`
    width: 24px;
    height: 24px;
    position: relative;
    margin-right: 16px;
    cursor: pointer;
    background: transparent;

    ${({ isActive }) =>
        isActive &&
        css`
            ${Glyph} {
                background-color: transparent;
                &:before,
                &:after {
                    top: 0;
                    transition: top 0.3s, transform 0.3s ease 0.3s;
                }
                &:before {
                    transform: rotate(135deg);
                }
                &:after {
                    transform: rotate(45deg);
                }
            }
        `}
`;

export const Glyph = styled.span`
    width: 20px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background-color: ${color('text-secondary')};
    border-radius: 2px;
    transition: all 0.3s;
    &:before,
    &:after {
        content: '';
        position: absolute;
        border-radius: 2px;
        background-color: ${color('text-secondary')};
        width: 20px;
        height: 2px;
        left: 0;
        transform: rotate(0deg);
        transition: top 0.3s linear 0.3s, transform 0.3s ease;
    }
    &:before {
        top: -6px;
    }
    &:after {
        top: 6px;
    }
`;
