import * as Yup from 'yup';

// Types
import { UnitTypes } from 'types/user/userApiInterface';

// TODO any issue
const getValidationSchema = (t: any, measurement: UnitTypes) => {
    const requiredHint = t('validation.required');

    const baseShape = {
        name: Yup.string()
            .trim()
            .min(2, t('validation.length.min', { count: 2 }))
            .max(25, t('validation.length.max', { count: 25 }))
            .required(requiredHint),
        last_name: Yup.string()
            .trim()
            .min(2, t('validation.length.min', { count: 2 }))
            .max(25, t('validation.length.max', { count: 25 }))
            .required(requiredHint),
        age: Yup.number()
            .required(requiredHint)
            .min(16, t('validation.age', { min: 16, max: 99 }))
            .max(99, t('validation.age', { min: 16, max: 99 })),
    };

    if (measurement === UnitTypes.Metric) {
        const heightLimits = {
            from: 40,
            to: 250,
        };
        const weightLimits = {
            from: 30,
            to: 150,
        };

        const heightLimitHint = t('validation.height.metric', heightLimits);
        const weightLimitHint = t('validation.weight.metric', weightLimits);

        return Yup.object().shape({
            ...baseShape,
            weight: Yup.number()
                .required(requiredHint)
                .min(weightLimits.from, weightLimitHint)
                .max(weightLimits.to, weightLimitHint),
            heightMetric: Yup.number()
                .required(requiredHint)
                .min(heightLimits.from, heightLimitHint)
                .max(heightLimits.to, heightLimitHint),
        });
    }

    if (measurement === UnitTypes.Imperial) {
        const weightLimits = { from: 66, to: 329 };

        const weightLimitHint = t('validation.weight.imperial', weightLimits);
        const heightLimitHint = t('validation.height.imperial', {
            from: 4,
            toFt: 7,
            toIn: 11,
        });

        return Yup.object().shape({
            ...baseShape,
            weight: Yup.number()
                .required(requiredHint)
                .min(weightLimits.from, weightLimitHint)
                .max(weightLimits.to, weightLimitHint),
            heightImperialFt: Yup.number().required(requiredHint).min(4, heightLimitHint).max(7, heightLimitHint),
            heightImperialIn: Yup.number().required(requiredHint).min(0, heightLimitHint).max(11, heightLimitHint),
        });
    }

    return Yup.object().shape(baseShape);
};

export default getValidationSchema;
