import React, { memo } from 'react';
import ReactModal from 'react-modal';

// styled
import { ContentStyle, OverlayStyle, CloseButton } from './styled';

import { Cross } from 'components/Icon';

// types
import { IModal } from './types';

ReactModal.defaultStyles = {};

const Modal = ({ children, isOpen, onClose, closeOnOverlayClick, withCloseButton, fullscreen }: IModal) => (
    <ReactModal
        shouldCloseOnOverlayClick={Boolean(closeOnOverlayClick)}
        shouldCloseOnEsc={false}
        isOpen={isOpen}
        contentLabel="Modal"
        contentElement={(props, content) => (
            <ContentStyle {...props} fullscreen={Boolean(fullscreen)}>
                {content}
            </ContentStyle>
        )}
        overlayElement={(props, contentElement) => <OverlayStyle {...props}>{contentElement}</OverlayStyle>}
        ariaHideApp={false}
    >
        {withCloseButton && (
            <CloseButton onClick={onClose}>
                <Cross />
            </CloseButton>
        )}
        {children}
    </ReactModal>
);

export default memo(Modal);
