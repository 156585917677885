import React from 'react';
import { withTheme } from 'styled-components';
import { Theme, Colors } from 'wikr-core-components';

const CheckCircle = ({ theme, color }: { theme: Theme; color?: Colors }) => {
    const fill = theme[color || 'success-default'];

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5068 9.95843C7.20209 9.64163 6.70805 9.64163 6.40334 9.95843C6.09863 10.2752 6.09863 10.7889 6.40334 11.1057L9.03577 13.8425L14.1963 8.47732C14.501 8.16052 14.501 7.64689 14.1963 7.3301C13.8916 7.0133 13.3975 7.0133 13.0928 7.3301L9.03577 11.548L7.5068 9.95843Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.1811 16.5031C13.8157 16.5031 16.7621 13.5566 16.7621 9.92202C16.7621 6.28741 13.8157 3.34097 10.1811 3.34097C6.54644 3.34097 3.6 6.28741 3.6 9.92202C3.6 13.5566 6.54644 16.5031 10.1811 16.5031ZM10.1811 18.1031C14.6993 18.1031 18.3621 14.4403 18.3621 9.92202C18.3621 5.40375 14.6993 1.74097 10.1811 1.74097C5.66278 1.74097 2 5.40375 2 9.92202C2 14.4403 5.66278 18.1031 10.1811 18.1031Z"
                fill={fill}
            />
        </svg>
    );
};

export default withTheme(CheckCircle);
