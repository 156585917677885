import { useTranslation } from 'react-i18next';

// helpers
import { getFormattedDate } from 'helpers/date';
import { replaceUnderscoreToDash } from 'helpers/utils';
import { getPriceFromCents } from 'helpers/prices';
import { getSubscriptionName } from './helpers';

// assets
import VipSupportIcon from 'assets/img/icons/vip-support-ico.svg';
import LogoIcon from 'assets/images/logo.svg';

// constants
import {
    DATE_FORMAT,
    SUBSCRIPTION_ACTIVE,
    SUBSCRIPTION_CANCELLED,
    SUBSCRIPTION_CHARGEBACK,
    SUBSCRIPTION_REDEMPTION,
} from './constants';
import { PRODUCT_CODES } from 'constants/product';

// types
import { ISubscriptionCard } from './types';

const TRIAL_NAME = 'subscription.type.trial';
const SUBSCRIPTION_NAME = 'subscription.type.subscription';

const useSubscriptionCard = ({ onCancelSubscription, subscription }: ISubscriptionCard) => {
    const {
        product: {
            amount,
            currency,
            trial_amount: trialAmount,
            subscription_period: subscriptionPeriod,
            subscription_trial_period: subscriptionTrialPeriod,
        },
        state,
        is_trial: isTrial,
        expired_date: expiredDate,
        cancelled_at: cancelledAt,
        product_code: productCode,
        external_id: externalId,
        upcoming_product: upcomingProduct,
    } = subscription;

    const { t } = useTranslation();

    const isCancelledSubscription = state === SUBSCRIPTION_CANCELLED || Boolean(cancelledAt);
    const isSubscriptionActive = state === SUBSCRIPTION_ACTIVE;
    const isSubscriptionRedemption = state === SUBSCRIPTION_REDEMPTION;
    const isExpiredSubscription =
        (isCancelledSubscription && !isSubscriptionActive) || state === SUBSCRIPTION_CHARGEBACK;
    const isExpiresSubscription = isCancelledSubscription && isSubscriptionActive;

    const expirationDate = getFormattedDate(expiredDate, DATE_FORMAT);
    const cancelledAtDate = getFormattedDate(cancelledAt, DATE_FORMAT);

    const tabIcon = productCode === PRODUCT_CODES.APP_VIP_SUPPORT ? VipSupportIcon : LogoIcon;
    const heading = replaceUnderscoreToDash(getSubscriptionName(productCode));
    const price = getPriceFromCents(isTrial ? trialAmount : amount);

    const trackCardExpand = (isTabExpanded: boolean) => {
        console.log('trackCardExpand', isTabExpanded);
    };

    const handleCancelSubscription = () => {
        onCancelSubscription({
            expirationDate,
            externalId,
            productCode,
        });
    };

    return {
        price: `${price} ${currency}`,
        state,
        tabIcon,
        heading,
        upcomingProduct,
        isSubscriptionRedemption,
        isExpiresSubscription,
        isExpiredSubscription,
        cancelledAtDate,
        trackCardExpand,
        handleCancelSubscription,
        subscriptionType: t(isTrial ? TRIAL_NAME : SUBSCRIPTION_NAME),
        expirationDate: replaceUnderscoreToDash(cancelledAt ? cancelledAtDate : expirationDate),
        subscriptionPeriod: isTrial ? subscriptionTrialPeriod : subscriptionPeriod,
    };
};

export default useSubscriptionCard;
