import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// helpers
import { isEmpty } from 'helpers/dataStructuresUtils';

// constants
import { SUBSCRIPTION_ACTIVE } from '../constants';

// components
import StatusIcon from './StatusIcon';
import PriceRow from './PriceRow';
import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import CancelButton from './CancelButton';
import UpcomingProduct from './UpcomingProduct';

// styled
import * as S from './styled';

// types
import { IDefaultSubscription } from '../types';

const DefaultSubscription = ({
    tabIcon,
    heading,
    state,
    subscriptionPeriod,
    price,
    expirationDate,
    onCancelSubscription,
    upcomingProduct,
    trackCardExpand,
    subscriptionType,
}: IDefaultSubscription) => {
    const { t } = useTranslation();
    const [isTabExpanded, setTabExpanded] = useState(false);

    const handleShowMore = () => {
        const newExpandedState = !isTabExpanded;

        setTabExpanded(newExpandedState);
        trackCardExpand(newExpandedState);
    };

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader
                icon={tabIcon}
                onShowMore={handleShowMore}
                heading={heading}
                isTabExpanded={isTabExpanded}
            />
            <S.GeneralInfo mb={0}>
                <S.Status>
                    <StatusIcon isActive={state === SUBSCRIPTION_ACTIVE} />
                    <Text type="caption" color="text-secondary" text={t(`subscription.status.${state}`)} />
                    <Text type="caption" color="text-secondary" text=":" />
                </S.Status>
                <PriceRow price={price} period={subscriptionPeriod} subscriptionType={subscriptionType} />
                {isTabExpanded && (
                    <S.DateInfo>
                        <Text type="caption" color="text-secondary" text={t('subscription.text.expires')} />
                        <Text type="caption" color="text-secondary" text={expirationDate} />
                    </S.DateInfo>
                )}
            </S.GeneralInfo>
            {upcomingProduct && !isEmpty(upcomingProduct) && (
                <UpcomingProduct upcomingProduct={upcomingProduct} isTabExpanded={isTabExpanded} />
            )}
            {isTabExpanded && <CancelButton onCancel={onCancelSubscription} />}
        </SubscriptionCardWrapper>
    );
};

export default DefaultSubscription;
