import config from 'appConfig';

import routes from 'router/routes';

export const PRIMARY_SIDEBAR_LINKS = [
    {
        to: routes.PRIVATE.MAIN.path,
        title: 'sidebar.app',
        dataLocator: 'main',
    },
    {
        to: routes.PRIVATE.FITNESS_GUIDES.path,
        title: 'sidebar.guides',
        dataLocator: 'fitnessGuides',
    },
    {
        to: routes.PRIVATE.ACCOUNT_DEFAULT.path,
        title: 'sidebar.settings',
        dataLocator: 'account',
    },
];

export const SECONDARY_SIDEBAR_LINKS = [
    {
        to: routes.PRIVATE.FAQ.path,
        title: 'sidebar.faq',
        dataLocator: 'faq',
    },
    {
        to: config.links.termsOfUse,
        title: 'sidebar.terms',
        dataLocator: 'termsOfUse',
        target: '_blank',
        external: true,
    },
    {
        to: config.links.privacyPolicy,
        title: 'sidebar.privacy',
        dataLocator: 'privacyPolicy',
        target: '_blank',
        external: true,
    },
    {
        to: config.links.refundPolicy,
        title: 'sidebar.refund',
        dataLocator: 'refundPolicy',
        target: '_blank',
        external: true,
    },
];
