import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// types
import { SidebarLinkButtonProps } from '../types';

// styled
import * as S from '../styled';

const SidebarLinkButton = ({ isActive, isPrimary, title }: SidebarLinkButtonProps) => {
    const { t } = useTranslation();
    const color = isActive || isPrimary ? 'text-main' : 'text-secondary';

    return (
        <S.NavButton>
            <Text type="medium-text" bold={isActive} color={color} text={t(title)} />
        </S.NavButton>
    );
};

export default SidebarLinkButton;
