import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Input, Theme } from 'wikr-core-components';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// store
import { selectCurrentUser } from 'store/user/selectors';

// hooks
import { useSelector } from 'hooks';

// types
import { InputType } from 'types/ui';

// config
import { getInputsData } from './config';

// styles
import * as S from './styled';

// components
import OutlinedButton from 'components/OutlinedButton';

const AccountSettings = ({ theme }: { theme: Theme }) => {
    const navigate = useNavigate();

    const user: any = useSelector(selectCurrentUser);

    const { t } = useTranslation();

    const deleteHandler = () => {
        console.log('Delete button clicked!');
    };

    return (
        <Box paddingTop={40}>
            {getInputsData(user.email).map(({ field, icon, value, label, buttonRoute }) => (
                <Fragment key={label}>
                    <S.StyledInput>
                        <Input
                            name={field}
                            label={label}
                            touched
                            leftIcon={icon}
                            disabled
                            isFocus
                            value={value}
                            initialValue={value}
                            onChange={() => {}}
                            theme={theme}
                            type={field as InputType}
                        />
                    </S.StyledInput>

                    {/* todo: remove 'styled' after lib issue will be fixed*/}
                    <S.SmallButton
                        size="small"
                        dataLocator="changeButton"
                        mb={40}
                        onClick={() => navigate(buttonRoute)}
                        text={t('account.settings.button.change')}
                    />
                </Fragment>
            ))}
            <OutlinedButton onClick={deleteHandler} text={t('account.settings.button.delete')} />
        </Box>
    );
};

export default withTheme(AccountSettings);
