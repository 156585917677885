import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Box } from 'wikr-core-components';

// layouts
import AuthLayout from 'router/layouts/AuthLayout';

// components
import ContactUsLink from 'components/ContactUsLink';
import AlertIcon from 'components/Icon/Alert';

import * as S from './styled';

export function ErrorFallback({ error }: { error: Error | null | undefined }) {
    const { t } = useTranslation();

    return (
        <AuthLayout titleKey="basics.appError">
            <S.ErrorBoundaryWrapper>
                <Box
                    backgroundColor="surface-secondary"
                    withShadow
                    paddingX={16}
                    paddingTop={40}
                    paddingBottom={40}
                    borderRadius={12}
                >
                    <Box mb={12}>
                        <AlertIcon size={50} />
                    </Box>
                    <Text
                        color="secondary-default"
                        type="h6"
                        text={t('basics.appError')}
                        bold
                        center
                        mb={32}
                        uppercase
                    />
                    {error?.message && (
                        <Text color="text-secondary-subdued" type="small-text" center mb={40} text={error.message} />
                    )}

                    <Button onClick={window.location.reload} text={t('basics.reload')} mb={24} />

                    <ContactUsLink subject={t('basics.errorMessage')} />
                </Box>
            </S.ErrorBoundaryWrapper>
        </AuthLayout>
    );
}
