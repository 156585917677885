import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';

// router
import routes from 'router/routes';

const SubscriptionHelpButton = () => {
    const { t } = useTranslation();

    return (
        <S.HelpButton>
            <Text type="medium-text" bold text={t('subscription.helpButton.question')} />
            <S.FaqLink to={routes.PRIVATE.FAQ.path} data-locator="faqButton">
                <Text type="caption" color="on-primary" bold text={t('subscription.helpButton.faq')} />
            </S.FaqLink>
        </S.HelpButton>
    );
};

export default SubscriptionHelpButton;
