import Skeleton from 'react-loading-skeleton';
import React, { useState, useEffect } from 'react';
import { Box, Button, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

// hooks
import { useDispatch, useSelector } from 'hooks';

// components
import InstallAppButton from 'components/InstallAppButton';
import { StepProgress } from 'components/StepProgress';
import { Step } from 'components/Step';
import Logo from 'components/Logo';

// images
import scanIconImg from 'assets/img/scan-icon-phone.svg';

// styled
import * as S from './styled';

// store
import { createProcessingSelector } from 'store/loading/selectors';
import { selectDeepLink } from 'store/deepLink/selectors';
import { GET_DEEP_LINK } from 'store/deepLink/actionTypes';
import { getDeepLinkRequest } from 'store/deepLink/actions';

// helpers
import BrowserStorage from 'api/utils/browserStorage';
import isMobile from 'helpers/isMobile';
import { getVideoLink } from 'helpers/getVideoLink';
import { getStepProgressData } from './helpers';

// styles
import 'react-loading-skeleton/dist/skeleton.css';

// images
import QRCode from 'qrcode.react';

// constants
import { STEPS } from './constants';

export const InstallApp = () => {
    const isLoading: boolean = useSelector(createProcessingSelector([GET_DEEP_LINK]));
    const dispatch = useDispatch();
    const deepLinkUrl = useSelector(selectDeepLink);
    const [isAppDownloaded, setIsAppDownloaded] = useState(BrowserStorage.getItem('isAppDownloaded'));

    const { t } = useTranslation();

    const videoLink = getVideoLink();
    const isAndroid = isMobile.android();
    const isIos = isMobile.iOS();
    const titleKey = isAppDownloaded ? 'main.title.isDownloaded' : 'main.title.notDownloaded';

    const handleDownloadApp = () => {
        setIsAppDownloaded(true);
        BrowserStorage.setItem('isAppDownloaded', true);

        window.open(deepLinkUrl, '_blank');
    };

    useEffect(() => {
        if (!deepLinkUrl) {
            dispatch(getDeepLinkRequest());
        }
    }, []);

    return (
        <>
            <S.Container paddingBottom={70} paddingX={16} paddingTop={31}>
                <>
                    <Text type="h5" mb={24} bold center text={`${t(titleKey)} 🙌`} />
                    {!isAppDownloaded && <StepProgress data={getStepProgressData(STEPS, 2)} />}
                    <S.StepContainer mb={24}>
                        <Step stepNumber={isAppDownloaded ? 1 : 3}>
                            <>
                                <Text type="large-text" bold mb={16} center text={t('main.step.title.getApp')} />
                                <S.TabletWrapper>
                                    <S.DownloadWrapper>
                                        <Image center maxWidth={48} mb={20} alt="scan-icon-img" src={scanIconImg} />
                                        <Text mb={56} center text={t('main.step.title.scanCode')} />
                                        <S.ScanQrCodeWrapper mb={58}>
                                            {isLoading ? (
                                                <Skeleton borderRadius={8} width={200} height={200} />
                                            ) : (
                                                <Box
                                                    paddingX={7}
                                                    paddingTop={7}
                                                    paddingBottom={7}
                                                    backgroundColor="on-primary"
                                                    borderRadius={16}
                                                >
                                                    <QRCode size={186} value={deepLinkUrl} />
                                                </Box>
                                            )}
                                        </S.ScanQrCodeWrapper>
                                    </S.DownloadWrapper>
                                </S.TabletWrapper>
                                <S.MobileWrapper>
                                    <S.FlexWrapper>
                                        <>
                                            {isIos && <InstallAppButton isMobile type="apple" />}
                                            {isAndroid && <InstallAppButton isMobile type="google" />}
                                        </>
                                    </S.FlexWrapper>
                                </S.MobileWrapper>
                            </>
                        </Step>
                        <Step stepNumber={isAppDownloaded ? 2 : 4}>
                            <>
                                <Text type="large-text" bold mb={16} center text={t('basics.login')} />
                                <S.Video maxWidth={190} mb={30} alt="video" src={videoLink} />
                            </>
                        </Step>
                        <S.MobileWrapper>
                            <Button
                                onClick={handleDownloadApp}
                                backgroundColor="primary-default"
                                mb={24}
                                text={t('main.button')}
                            />
                        </S.MobileWrapper>
                    </S.StepContainer>
                    <S.TabletWrapper>
                        <S.Footer
                            borderRadius={16}
                            paddingBottom={24}
                            paddingTop={24}
                            paddingX={24}
                            backgroundColor="surface-secondary"
                            mb={56}
                        >
                            <Logo />
                            <S.FlexWrapper>
                                <InstallAppButton type="apple" />
                                <InstallAppButton type="google" />
                            </S.FlexWrapper>
                        </S.Footer>
                    </S.TabletWrapper>
                </>
            </S.Container>
        </>
    );
};
