const SESSION_STORAGE_KEYS: string[] = [];

const innerStorage: any = {
    data: {},
    getItem: (key: string) => innerStorage.data[key],
    setItem: (key: string, value: any) => (innerStorage.data[key] = value),
    removeItem: (key: string) => {
        delete innerStorage.data[key];
    },
};

const checkIfStorageExist = () => window && sessionStorage && localStorage;

const BrowserStorage = {
    setItem: (key: string, value: any) => BrowserStorage.getStorage(key).setItem(key, JSON.stringify(value)),
    getItem: (key: string) => {
        const value = BrowserStorage.getStorage(key).getItem(key);

        try {
            if (value) {
                return JSON.parse(value);
            }
        } catch (err) {
            return value;
        }
    },
    removeItem: (key: string) => BrowserStorage.getStorage(key).removeItem(key),
    getStorage: (key: string) => {
        if (!checkIfStorageExist()) return innerStorage;

        if (SESSION_STORAGE_KEYS.includes(key)) return sessionStorage;

        return localStorage;
    },
};

export default BrowserStorage;
