import React, { Component } from 'react';

// types
import { Props, State } from './interface';

// services
import sentry from 'services/Sentry/SentryInstance';

// constants
import { ERROR_LEVELS, SENTRY_CABINET } from 'services/Sentry/constants';

// components
import { ErrorFallback } from './ErrorFallback';

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null,
    };

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public logError(error: Error) {
        sentry.logError(error, SENTRY_CABINET, ERROR_LEVELS.CRITICAL);
    }

    public componentDidCatch(error: Error) {
        this.setState({ error });

        this.logError(error);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorFallback error={this.state.error} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
