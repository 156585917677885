import { formValues } from './types';

export const initialValues: formValues = {
    name: '',
    last_name: '',
    age: 0,
    gender: '',
    weight: 0,
    heightMetric: 0,
    heightImperialIn: 0,
    heightImperialFt: 0,
};

export const metricMeasureFields = { weight: 'weight', heightMetric: 'heightMetric' };
export const imperialMeasureFields = {
    weight: 'weight',
    heightImperialFt: 'heightImperialFt',
    heightImperialIn: 'heightImperialIn',
};

export const metricMeasuresKeys = Object.keys(metricMeasureFields);
export const imperialMeasuresKeys = Object.keys(imperialMeasureFields);
