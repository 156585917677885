// @TODO move to npm package
export default [
    // Network errors such as going offline or being blocked by a proxy
    /.*Failed to fetch.*/g,
    /.*Network Error.*/g,
    /.*Load failed.*/g,

    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',

    /.*e.extra.agent.*/g,
    // https://app.asana.com/0/1201150002098993/1202216859250751/f
    /.*timeout of 10000ms exceeded.*/g,
    /.*undefined is not an object (evaluating 't.default').*/g,
    /.*Cannot find module.*/g,
    /.*OneTrust: Invalid domain id.*/g,
    /.*CSS_CHUNK_LOAD_FAILED.*/g,
    /.*Required token is missing.*/g,
    /.*Token Required.*/g,
    /.*Loading chunk.*/g,
    /.*Loading CSS chunk.*/g,
    /.*code 409.*/g,
    /.*code 400.*/g,
    /.*Suspected Fraud.*/g,
    /.*Failed to execute 'insertBefore'.*/g,

    // solidSdk: PayPal button error (close payPal popup)
    /.*isTrusted.*/g,
];
