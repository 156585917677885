import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// hooks
import { useLocation } from 'react-router-dom';
import { useSelector } from 'hooks';

// routes
import routes from 'router/routes';

// component
import Logo from 'components/Logo';

// styles
import * as S from './styled';
import { LogInLink } from './styled';

const HeaderPublic = () => {
    const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const isRoot = pathname === routes.PUBLIC.LOGIN.path;

    return (
        <S.HeaderPublicContainer>
            <S.TitleContainer>
                <Logo />
            </S.TitleContainer>

            {!isRoot && !isAuthenticated && (
                <LogInLink to="/">
                    <Text color="text-main" type="small-button" text={t('basics.login')} />
                </LogInLink>
            )}
        </S.HeaderPublicContainer>
    );
};

export default HeaderPublic;
