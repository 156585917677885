import styled from 'styled-components';

export const FormWrap = styled.form`
    position: relative;
    width: ${({ theme }) => `calc(400px + (${theme['default-indent']}px * 2))`};
    margin: 0 auto;
    padding-top: 32px;
`;

export const ForgotPassContainer = styled.div`
    width: 100vw;
    min-height: calc(100vh - 56px);
    display: flex;
    flex-direction: row;
`;
