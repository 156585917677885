// hooks
import { useToggle } from 'hooks';
import { useNavigate } from 'react-router-dom';

// router
import routes from 'router/routes';

// components
import Burger from 'components/Burger';
import Logo from 'components/Logo';

// styles
import * as S from './styled';

// assets
import defaultUserPic from 'assets/images/default-avatar.svg';

const Header = () => {
    const navigate = useNavigate();

    const { isOpen, toggle, close } = useToggle();

    const handleRedirect = () => {
        navigate(routes.PRIVATE.ACCOUNT_DEFAULT.path);
        close();
    };

    return (
        <S.HeaderContainer>
            <S.TitleContainer>
                <Burger onClick={toggle} isActive={isOpen} />
                <Logo />
            </S.TitleContainer>
            <S.AvatarImage onClick={handleRedirect} src={defaultUserPic} />
        </S.HeaderContainer>
    );
};

export default Header;
