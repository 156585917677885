import { useTranslation } from 'react-i18next';

// images
import passwordIcon from 'assets/img/icons/passwordIcon.webp';

export const getFields = () => {
    const { t } = useTranslation();

    return [
        {
            name: 'oldPassword',
            label: t('change.password.currentPassword'),
            type: 'password',
            icon: passwordIcon,
            dataLocator: 'oldPasswordInput',
        },
        {
            name: 'newPassword',
            label: t('change.password.newPassword'),
            type: 'password',
            icon: passwordIcon,
            dataLocator: 'newPasswordInput',
        },
        {
            name: 'confirmNewPassword',
            label: t('change.password.confirmPassword'),
            type: 'password',
            icon: passwordIcon,
            dataLocator: 'confirmNewPasswordInput',
        },
    ];
};
