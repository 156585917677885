// helpers
import { getQrCodeLink } from 'helpers/getQrCodeLink';

// types
import { InferValueTypes } from 'types/commonTypes';
import * as actionTypes from './actionTypes';

// actionTypes
import * as action from './actions';

const initialState = {
    url: '',
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const deepLinkReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.GET_DEEP_LINK_SUCCESS:
            return { ...state, url: action.payload };

        case actionTypes.GET_DEEP_LINK_ERROR:
            return { ...state, url: getQrCodeLink() };

        default:
            return state;
    }
};

export default deepLinkReducer;
