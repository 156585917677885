import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// store
import {
    authentication,
    authenticationBySignature,
    setAuthenticationStatus,
    setAuthRedirectUrl,
} from 'store/auth/actions';

// hooks
import { useDispatch } from 'hooks/store';

// api
import { getToken, removeToken, setToken } from 'api/utils/tokenManagement';

// helpers
import isPrivateRoute from 'router/helpers/isPrivateRoute';

// services
import { initAnalytics } from 'services/analytics';
import { initHotjar } from 'services/Hotjar';

// router
import { PRIVATE, PUBLIC } from 'router/routes';

// constants
import { AUTH_TOKEN_NAME } from 'constants/api';
import { accountTabs } from 'containers/AccountSettings/constants';

const USER_ID_PARAM_NAME = 'c';
const SIGNATURE_PARAM_NAME = 'sg';

export const useAuthentication = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search, pathname } = useLocation();

    const isPrivate = isPrivateRoute(pathname);

    const params = new URLSearchParams(search);

    useEffect(() => {
        const uId = params.get(USER_ID_PARAM_NAME);
        const signature = params.get(SIGNATURE_PARAM_NAME);

        initAnalytics();
        initHotjar();

        if (uId && signature) {
            const subscriptionsPage = `${PRIVATE.ACCOUNT_DEFAULT.path}/${accountTabs.Subscription}`;

            dispatch(setAuthRedirectUrl(subscriptionsPage));
            dispatch(
                authenticationBySignature({
                    uId,
                    signature,
                    onSuccess: () => {
                        navigate(subscriptionsPage);
                    },
                    onError: () => {
                        navigate(PUBLIC.LOGIN.path);
                    },
                })
            );

            return;
        }

        const tokenFromUrl = params.get(AUTH_TOKEN_NAME);

        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }

        if (!getToken()) {
            isPrivate && dispatch(setAuthRedirectUrl(pathname));

            dispatch(setAuthenticationStatus(false));

            return;
        }

        dispatch(
            authentication({
                onSuccess: () => {
                    navigate(pathname);
                },
                onError: () => {
                    removeToken();
                    isPrivate && dispatch(setAuthRedirectUrl(pathname));
                    navigate(PUBLIC.LOGIN.path);
                },
            })
        );
    }, []);
};
