import styled from 'styled-components';
import { Box } from 'wikr-core-components';

import { StepCircleProps } from './types';

// theme
import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Container = styled(Box)`
    display: flex;
    justify-content: center;
    // inner padding fot text under the steps + padding from figma design (todo: need to be refactored)
    padding-bottom: calc(24px + 36px);

    ${mediaQueries.tabletLandscape} {
        padding-bottom: calc(47px + 36px);
    }
`;

export const Circle = styled.div<StepCircleProps>`
    font-weight: 700;
    width: 32px;
    height: 32px;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${color('border-default')};

    p {
        color: ${color('text-secondary')};
    }

    ${({ isCurrentStep, theme }) =>
        isCurrentStep && {
            backgroundColor: theme['success-surface'],
            borderColor: theme['success-default'],
        }}

    ${({ isFinished, theme }) =>
        isFinished && {
            borderColor: theme['success-default'],
            backgroundColor: theme['success-default'],
        }}
`;

export const CircleContainer = styled.div`
    position: relative;
`;

export const Line = styled.div<{ isFinished: boolean }>`
    width: 90.67px;
    height: 2px;

    @media (max-width: 768px) {
        width: 63.67px;
    }

    ${({ isFinished, theme }) => `background-color: ${isFinished ? theme['success-default'] : theme['border-default']}`}
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

export const LabelContainer = styled.div`
    position: absolute;
    margin-top: 8px;
    width: 50px;
    right: -9px;
`;
