import React from 'react';
import { Trans } from 'react-i18next';

// config
import links from 'appConfig/links';

// styled
import { Paragraph } from './styled';

interface ILink {
    children?: JSX.Element;
    href: string;
}

const Link = ({ children, href }: ILink) => (
    <a href={href} target="_blank" rel="noreferrer">
        {children}
    </a>
);

export default [
    {
        id: 0,
        title: 'faq.question.subscribed',
        content: (
            <Trans
                i18nKey="faq.answer.subscribed"
                components={{
                    paragraph: <Paragraph mb={16} />,
                    linkMain: <Link href="/main" />,
                    spanImage: <span role="img" aria-label="!" />,
                }}
            />
        ),
    },
    {
        id: 1,
        title: 'faq.question.password',
        content: (
            <Trans
                i18nKey="faq.answer.password"
                components={{
                    paragraph: <Paragraph mb={16} />,
                    div: <Paragraph />,
                    linkApple: <Link href="https://apps.apple.com/us/app/id1553036888" />,
                    linkAndroid: (
                        <Link href="https://play.google.com/store/apps/details?id=fasteasy.dailyburn.fastingtracker&hl=en&gl=US" />
                    ),
                }}
            />
        ),
    },
    {
        id: 2,
        title: 'faq.question.cancel',
        content: (
            <Trans
                i18nKey="faq.answer.cancel"
                components={{
                    paragraph: <Paragraph mb={16} />,
                    div: <Paragraph />,
                    linkSubscription: <Link href="/account/subscription" />,
                    spanImage: <span role="img" aria-label="!" />,
                }}
            />
        ),
    },
    {
        id: 3,
        title: 'faq.question.refund',
        content: (
            <Trans
                i18nKey="faq.answer.refund"
                components={{
                    linkRefund: <Link href={links.refundPolicy} />,
                }}
            />
        ),
    },
    {
        id: 4,
        title: 'faq.question.guides',
        content: (
            <Trans
                i18nKey="faq.answer.guides"
                components={{
                    div: <Paragraph />,
                    linkRefund: <Link href="/guides" />,
                }}
            />
        ),
    },
    {
        id: 5,
        title: 'faq.question.problem',
        content: (
            <Trans
                i18nKey="faq.answer.problem"
                components={{
                    div: <Paragraph />,
                }}
            />
        ),
    },
];
