import { put, takeLatest, all, call } from 'redux-saga/effects';

// api
import api from 'api';

// actions
import { setGuides, setLoadingState } from './actions';
import { notifyError } from '../notifications/actions';

// actionTypes
import { FETCH_GUIDES } from './actionTypes';

// types
import { IGuide } from 'types/guide';

function* getGuides() {
    try {
        const response: IGuide[] = yield call(api.user.getProducts);

        yield put(setGuides(response));
        yield put(setLoadingState(false));
    } catch (error) {
        notifyError('getGuides error');
    }
}

export default function* watchGuides() {
    yield all([takeLatest(FETCH_GUIDES, getGuides)]);
}
