export const reasons = [
    {
        title: 'subscription.cancellationReason.noAppNeed',
        shortKey: 'no_app_need',
    },
    {
        title: 'subscription.cancellationReason.tooExpensive',
        shortKey: 'too_expensive',
    },
    {
        title: 'subscription.cancellationReason.unawarePaymentTerms',
        shortKey: 'unaware_payment_terms',
    },
    {
        title: 'subscription.cancellationReason.notMeetsExpectations',
        shortKey: 'app_not_meets_expectations',
    },
    {
        title: 'subscription.cancellationReason.technicalIssues',
        shortKey: 'app_technical_issues',
    },
    {
        title: 'subscription.cancellationReason.didNotReceiveProduct',
        shortKey: 'did_not_receive_product',
    },
    {
        title: 'subscription.cancellationReason.other',
        shortKey: 'other',
    },
];
