import styled from 'styled-components';

export const ErrorBoundaryWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    min-height: calc(100vh - 56px);
    text-align: center;
`;
