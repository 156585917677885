export const measure = {
    MEASURE_UNITS: {
        imperial: 'lbs, in',
        metric: 'kg, cm',
    },
    WEIGHT_UNIT: { imperial: 'lbs', metric: 'kg' },
    TALL_UNIT: {
        imperial: {
            ft: 'ft',
            in: 'in',
        },
        metric: 'cm',
    },
};
