import { useState, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Box, Text } from 'wikr-core-components';

// styled
import * as S from './styled';

interface AccordionProps {
    title: string;
    content: ReactNode;
}

export const Accordion = ({ title, content }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggle = () => setIsOpen(!isOpen);

    return (
        <S.Wrapper onClick={handleToggle}>
            <Box backgroundColor="surface-secondary" borderRadius={12} paddingTop={18} paddingX={16} paddingBottom={18}>
                <S.Header>
                    <S.FaqButton>
                        <Text type="small-text">
                            <Trans i18nKey={title} />
                        </Text>
                    </S.FaqButton>
                    <S.PlusSwitcher isOpen={isOpen}>
                        <div />
                        <div />
                    </S.PlusSwitcher>
                </S.Header>
                {isOpen && <S.Content type="small-text">{content}</S.Content>}
            </Box>
        </S.Wrapper>
    );
};
