import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';

interface StepProps {
    stepNumber: number;
    children: JSX.Element;
}

export const Step = ({ stepNumber, children }: StepProps) => {
    const { t } = useTranslation();

    return (
        <S.Container backgroundColor="surface-secondary" borderRadius={16} paddingX={24} paddingTop={26}>
            <S.StepNumber
                backgroundColor="surface-main"
                borderRadius={20}
                borderColor="border-default"
                paddingBottom={8}
                paddingTop={8}
                paddingX={16}
            >
                <Text type="overline" text={t('main.step.number', { count: stepNumber })} />
            </S.StepNumber>
            {children}
        </S.Container>
    );
};
