import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/SideBar';

// hooks
import useFrontChat from 'hooks/frontChat/useFrontChat';

const CabinetLayout = ({ children, titleKey }: { children: ReactNode; titleKey: string }) => {
    useFrontChat();

    const { t } = useTranslation();

    return (
        <>
            <Helmet title={t(titleKey)} />
            <Header />
            <Sidebar />
            {children}
        </>
    );
};

export default CabinetLayout;
