import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';

// types
import { ICancelButton } from '../types';

const CancelButton = ({ onCancel }: ICancelButton) => {
    const { t } = useTranslation();

    return (
        <S.CancelButton onClick={onCancel} data-locator="cancelSubscriptionButton">
            <Text type="overline" bold text={t('subscription.text.cancel')} />
        </S.CancelButton>
    );
};

export default CancelButton;
