// config
import config from 'config';

// api
import ApiClient from './apiClient';
import UserApi from './components/User';
import Subscriptions from './components/Subscriptions';

function ApiInstance() {
    const apiClient = new ApiClient({ apiUrl: config.API_URL, isSandbox: false });
    const user = new UserApi({ apiClient });
    const subscriptions = new Subscriptions({ apiClient });

    return {
        apiClient,
        user,
        subscriptions,
    };
}

export default ApiInstance();
