import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Box } from 'wikr-core-components';

// components
import OverlineButton from 'components/OverlineButton';
import Modal from '../Modal';

// constants
import { reasons } from './constants';

// styled
import * as S from './styled';

// helpers
import isMobile from 'helpers/isMobile';

// types
import { ICancellationReasonModal } from './types';

const CancellationReasonModal = ({ isOpen, onSubmit, onDiscard, onClose }: ICancellationReasonModal) => {
    const { t } = useTranslation();
    const [reason, setReason] = useState<string | null>(null);

    const handleSelectReason = (shortKey: string) => () => setReason(shortKey);

    const handleDiscard = () => {
        setReason(null);
        onDiscard();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} fullscreen={isMobile.any()}>
            <Box paddingTop={20} paddingBottom={20}>
                <Text type="h5" color="text-main" center mb={24} text={t('subscription.cancellationReason.why')} />
                <Box mb={32}>
                    {reasons.map(({ title, shortKey }) => (
                        <S.ReasonItem
                            key={shortKey}
                            onClick={handleSelectReason(shortKey)}
                            isSelected={shortKey === reason}
                        >
                            <Text type="medium-text" bold text={t(title)} />
                        </S.ReasonItem>
                    ))}
                </Box>

                <Button
                    disabled={!reason}
                    onClick={() => reason && onSubmit(reason)}
                    mb={20}
                    text={t('basics.continue')}
                />
                <OverlineButton text={t('subscription.cancelDialog.discard')} onClick={handleDiscard} />
            </Box>
        </Modal>
    );
};

export default CancellationReasonModal;
