import dayjs from 'dayjs';

export const getBirthdayByAge = (age: number): string => {
    const currentYear = new Date().getFullYear();

    return `${currentYear - age}-01-01`;
};

export default getBirthdayByAge;

export const getAgeFromBirthday = (birthday: string): number => {
    if (!birthday) return 0;

    const today = new Date();
    const todayYear = today.getFullYear();
    const yearOfBirth = birthday.split('-')[0];

    return todayYear - Number(yearOfBirth);
};

export const getFormattedDate = (date: string, format: string): string => {
    return dayjs(date).format(format);
};
