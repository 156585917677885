import video from './video';
import installApp from './installApp';
import links from './links';
import base from './base';

export default {
    video,
    installApp,
    links,
    base,
};
