import React from 'react';

// styles
import 'react-toastify/dist/ReactToastify.css';
import StyledContainer from './styled';

const AUTO_CLOSE_DELAY = 3000;

const NotificationsContainer = () => (
    <StyledContainer
        position="top-right"
        autoClose={AUTO_CLOSE_DELAY}
        hideProgressBar
        closeOnClick
        draggable
        closeButton={false}
    />
);

export default NotificationsContainer;
