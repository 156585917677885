// redux
import { isAuthenticatedSelector } from 'store/auth/selectors';

// styles
import * as S from './styled';

// types
import { ILogoProps } from './types';

// hooks
import { useSelector } from 'hooks';

// router
import routes from 'router/routes';

const Logo = ({ withoutText, height }: ILogoProps) => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    const logoLink = isAuthenticated ? routes.PRIVATE.MAIN.path : routes.PUBLIC.LOGIN.path;

    return (
        <S.LogoContainer to={logoLink} height={height}>
            <S.LogoImage />
            {!withoutText && <S.LogoText />}
        </S.LogoContainer>
    );
};

export default Logo;
