import React, { ReactNode } from 'react';

// components
import { Box } from 'wikr-core-components';

const SubscriptionCardWrapper = ({ children }: { children: JSX.Element | JSX.Element[] | ReactNode }) => {
    return (
        <Box
            mb={20}
            paddingX={16}
            paddingTop={16}
            borderRadius={12}
            paddingBottom={16}
            backgroundColor="surface-default"
        >
            {children}
        </Box>
    );
};

export default SubscriptionCardWrapper;
