// hooks
import { useDispatch, useSelector } from 'hooks/store';

// store
import { selectIsOpenSidebar } from 'store/uiEffects/selectors';
import { setIsOpenSideBar } from 'store/uiEffects/actions';

const useToggle = () => {
    const dispatch = useDispatch();
    const isOpenSidebar = useSelector(selectIsOpenSidebar);

    const open = () => {
        dispatch(setIsOpenSideBar(true));
    };

    const toggle = () => {
        dispatch(setIsOpenSideBar(!isOpenSidebar));
    };

    const close = () => {
        dispatch(setIsOpenSideBar(false));
    };

    return { open, close, toggle, isOpen: isOpenSidebar };
};

export default useToggle;
