import React from 'react';
import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

// config
import base from 'appConfig/base';

const ContactUsLink = ({ subject }: { subject: string }) => {
    const { t } = useTranslation();

    return (
        <a href={`mailto:${base.supportEmail}?subject=${subject}`} target="_blank" rel="noreferrer">
            <Text type="overline" center bold text={t('basics.contactUs')} />
        </a>
    );
};

export default ContactUsLink;
