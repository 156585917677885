import styled, { css } from 'styled-components';
import { color } from 'theme/selectors';

const fullscreenContent = css`
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
`;

const defaultContent = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    max-width: 432px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
`;

export const ContentStyle = styled.div<{ fullscreen?: boolean }>`
    padding: 24px;
    background-color: ${color('surface-main')};

    ${({ fullscreen }) => (fullscreen ? fullscreenContent : defaultContent)}
`;

export const OverlayStyle = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px;
    z-index: 1000;
    background: ${color('secondary-default', 0.5)} !important;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    background: transparent;
    right: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;

    svg path {
        transition: all 0.3s ease;
    }

    &:hover {
        background: ${color('surface-secondary')};

        svg path {
            fill: ${color('text-main')};
        }
    }
`;
