export default {
    videoLinks: {
        ios: {
            EN: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/los/Ios_EN.webp',
            ES: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/los/Ios_ES.webp',
            PT: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/los/Ios_PT.webp',
            IT: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/los/Ios_IT.webp',
            DE: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/los/Ios_DE.webp',
            FR: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/los/Ios_FR.webp',
            CH: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/los/Ios_CH.webp',
        },
        android: {
            EN: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/Android/Android_EN.webp',
            ES: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/Android/Android_ES.webp',
            PT: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/Android/Android_PT.webp',
            IT: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/Android/Android_IT.webp',
            DE: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/Android/Android_DE.webp',
            FR: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/Android/Android_FR.webp',
            CH: 'https://s3.eu-central-1.amazonaws.com/videoproduction-main.wikrgroup.com/%21VIDEO/MyCoachApp/Public/FEw/Android/Android_CH.webp',
        },
    },
};
