import styled from 'styled-components';
import { Box, Button } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';

export const CheckMailBox = styled(Box)`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
`;

export const LogInButton = styled(Button)`
    background: ${color('primary-default')} !important;

    :hover {
        background: ${color('primary-active')} !important;
    }
`;
