import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Box } from 'wikr-core-components';
import { useNavigate } from 'react-router-dom';

// styles
import * as S from './styled';

// subComponents
import SidebarLink from './components/SidebarLink';

// constants
import { PRIMARY_SIDEBAR_LINKS, SECONDARY_SIDEBAR_LINKS } from './constants';

// hooks
import { useToggle, useDispatch } from 'hooks';

// store
import { logout } from 'store/auth/actions';

// types
import { linkType } from './types';

export default function SideBar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { isOpen, close } = useToggle();

    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
        close();
    };

    return (
        <S.SideBarWrapper isOpen={isOpen}>
            <S.SideBarOverlay onClick={close} />
            <S.SideBarMenu>
                <Box paddingTop={32} paddingX={8}>
                    {PRIMARY_SIDEBAR_LINKS.map(({ to, title, dataLocator }) => (
                        <SidebarLink
                            key={dataLocator}
                            onClick={close}
                            to={to}
                            title={title}
                            dataLocator={dataLocator}
                            type={linkType.Primary}
                        />
                    ))}
                    <S.Hr />
                </Box>

                <Box paddingTop={32} paddingX={8}>
                    {SECONDARY_SIDEBAR_LINKS.map(({ dataLocator, ...rest }) => (
                        <SidebarLink
                            key={dataLocator}
                            onClick={close}
                            dataLocator={dataLocator}
                            type={linkType.Secondary}
                            {...rest}
                        />
                    ))}

                    <S.NavButton onClick={handleLogout}>
                        <Text type="medium-text" color="text-secondary" text={t('sidebar.logout')} />
                    </S.NavButton>
                    <S.Hr />
                </Box>
            </S.SideBarMenu>
        </S.SideBarWrapper>
    );
}
