export enum accountTabs {
    Profile = 'profile',
    Subscription = 'subscription',
    Settings = 'settings',
}

export const TABS = [
    { id: accountTabs.Profile, title: 'account.tabsTitles.profile' },
    { id: accountTabs.Subscription, title: 'account.tabsTitles.subscription' },
    { id: accountTabs.Settings, title: 'account.tabsTitles.settings' },
];
