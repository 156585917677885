import { useTranslation } from 'react-i18next';

// router
import routes from 'router/routes';

// assets
import emailIcon from 'assets/img/icons/emailIcon.webp';
import passwordIcon from 'assets/img/icons/passwordIcon.webp';

export const getInputsData = (email: string) => {
    const { t } = useTranslation();

    return [
        {
            field: 'email',
            icon: emailIcon,
            value: email,
            label: t('input.email.label'),
            buttonRoute: routes.PRIVATE.CHANGE_EMAIL.path,
        },
        {
            field: 'text',
            icon: passwordIcon,
            value: '••••••••',
            label: t('input.password.placeholder'),
            buttonRoute: routes.PRIVATE.CHANGE_PASSWORD.path,
        },
    ];
};
