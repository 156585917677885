import { Text } from 'wikr-core-components';

// components
import { Guide } from 'components/Guide';

// types
import { IGuide } from 'types/guide';

// styled
import * as S from './styled';

const GuidesList = ({ guides, title }: { title: string; guides: IGuide[] }) => (
    <>
        <Text type="caption" color="text-secondary-subdued" bold text={title} mb={8} />
        <S.GuidesList>
            {guides.map(({ name, preview, download_url: downloadUrl }: IGuide) => (
                <S.GuidesListItem key={name}>
                    <Guide title={name} img={preview} link={downloadUrl} />
                </S.GuidesListItem>
            ))}
        </S.GuidesList>
    </>
);

export default GuidesList;
