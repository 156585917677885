export const DEFAULT_CURRENCY = '$';

export const PASSWORD_MIN_LENGTH = 6;

export const PASSWORD_MAX_LENGTH = 12;

export const GENDERS = {
    MALE: 'male',
    FEMALE: 'female',
};
