import styled from 'styled-components';

import { Box } from 'wikr-core-components';

// theme
import { color } from 'theme/selectors';
import mediaQueries from 'theme/mediaQueries';

export const Container = styled(Box)`
    width: 100%;
    margin: 0 auto;
    max-width: unset;

    ${mediaQueries.tablet} {
        max-width: 400px;
    }
`;

export const List = styled.ul`
    margin-bottom: 40px;
`;

export const ListItem = styled(Box)`
    width: 100%;
`;

export const ContactUsLink = styled.a`
    text-align: center;
    display: block;
    text-transform: uppercase;
    background: ${color('primary-default')};
    border-radius: 12px;
    cursor: pointer;

    > * {
        padding: 20px;
        letter-spacing: 2px;
    }

    :hover {
        background: ${color('primary-active')};
    }
`;

export const Paragraph = styled.span<{ mb?: string | number }>`
    display: block;
    margin-bottom: ${({ mb = 0 }) => mb}px;
`;
