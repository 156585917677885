import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Button, Text, Box } from 'wikr-core-components';
import { Link, useNavigate } from 'react-router-dom';

// router
import routes from 'router/routes';

// styles
import * as S from './styled';

// components
import FormikInput from 'components/FormikInput';

// hooks
import { useDispatch, useNotify, useSelector } from 'hooks';

// store
import { changePasswordRequest } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';
import { CHANGE_PASSWORD } from 'store/user/actionTypes';

// validation
import { ChangePasswordSchema } from './validationSchema';

// helpers
import { isEmpty } from 'helpers/dataStructuresUtils';

// config
import { getFields } from './config';

// constants
import { accountTabs } from 'containers/AccountSettings/constants';

const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
    oldPassword: '',
};

export const ChangePassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { notifySuccess, notifyError } = useNotify();
    const { t } = useTranslation();

    const isLoading: boolean = useSelector(createProcessingSelector([CHANGE_PASSWORD]));

    const { handleSubmit, errors, values, dirty, setFieldTouched, setFieldValue, touched } = useFormik({
        validationSchema: ChangePasswordSchema,
        initialValues,
        onSubmit: () => {
            const settingsTabUrl = `${routes.PRIVATE.ACCOUNT_DEFAULT.path}/${accountTabs.Settings}`;

            dispatch(
                changePasswordRequest({
                    payload: {
                        old_password: values.oldPassword,
                        new_password: values.newPassword,
                    },
                    onSuccess: () => {
                        notifySuccess(t('change.password.success'));
                        navigate(settingsTabUrl);
                    },
                    onError: () => {
                        notifyError(t('change.password.error'));
                        navigate(settingsTabUrl);
                    },
                })
            );
        },
    });

    return (
        <S.Container paddingTop={32} paddingX={16} paddingBottom={32}>
            <Text center mb={20} type="h5" text={t('change.password.title')} />
            <form>
                <Box mb={44}>
                    {getFields().map(({ name, label, type, icon, dataLocator }) => (
                        <FormikInput
                            key={name}
                            type={type}
                            field={name}
                            label={label}
                            setFieldTouched={setFieldTouched}
                            initialValues={initialValues}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            errors={errors}
                            mb={32}
                            leftIcon={icon}
                            dataLocator={dataLocator}
                            placeholder={label}
                        />
                    ))}
                </Box>
                <Button
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    disabled={!dirty || !isEmpty(errors)}
                    text={t('save')}
                    mb={24}
                />
            </form>
            <Link to={routes.PRIVATE.AUTHORISED_FORGOT_PASSWORD.path}>
                <Text type="overline" center bold text={t('basics.forgotPassword')} />
            </Link>
        </S.Container>
    );
};
