import React from 'react';
import { Text, Box } from 'wikr-core-components';

// components
import OutlinedButton from 'components/OutlinedButton';
import Modal from '../Modal';

// styled
import * as S from './styled';

// types
import { IWaitingModal } from './types';

const WaitingModal = ({ isOpen, onDiscard, onClose, captions }: IWaitingModal) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <Box paddingTop={20} paddingBottom={20}>
            <Text type="large-text" color="text-main" bold center mb={24} text={captions.title} />
            <Box>
                <S.Spinner />
            </Box>
            {onDiscard && <OutlinedButton text={captions.discardButton} onClick={onDiscard} />}
        </Box>
    </Modal>
);

export default WaitingModal;
