import React from 'react';

// styles
import * as S from './styled';

// components
import ForgotPasswordForm from 'components/ForgotPasswordForm';

export const AuthorisedForgotPassword = () => {
    return (
        <S.ForgotPassContainer>
            <S.FormWrap>
                <ForgotPasswordForm />
            </S.FormWrap>
        </S.ForgotPassContainer>
    );
};
