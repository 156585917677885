export const isEmpty = (value: null | undefined | string | number | object): boolean => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0) ||
        (typeof value === 'number' && Number(value) === Infinity)
    );
};

export const replaceUnderscoreToDash = (string: string): string => string.replace(/_/g, '-');
