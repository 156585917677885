import React, { ChangeEvent } from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

// components
import { Box, Button, Text } from 'wikr-core-components';
import FormikInput from 'components/FormikInput';
import { Radio } from 'components/Radio';
import { GENDERS } from 'constants/user';

// types
import { UnitTypes } from 'types/user/userApiInterface';
import { weightInput, nameInput, formValues } from '../types';

// helpers
import { isEmpty } from 'helpers/dataStructuresUtils';

// styled
import * as S from '../styled';

// constants
import { measure } from 'constants/units';

interface IProfileForm {
    currentUnit: UnitTypes;
    nameFields: nameInput[];
    weightFields: weightInput[];
    setUnit: (event: ChangeEvent) => void;
    isLoading?: boolean;
}

const { MEASURE_UNITS, WEIGHT_UNIT, TALL_UNIT } = measure;

function ProfileForm({
    nameFields,
    setFieldTouched,
    values,
    setFieldValue,
    touched,
    errors,
    handleChange,
    currentUnit,
    weightFields,
    handleSubmit,
    setUnit,
    isLoading,
}: IProfileForm & FormikProps<formValues>) {
    const { t } = useTranslation();

    const measureUnits = Object.values(MEASURE_UNITS);

    const genderToggleHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFieldTouched('gender');
        handleChange(e);
    };

    return (
        <Box paddingTop={40}>
            {nameFields.map(({ name, label, dataLocator, placeholder }) => (
                <FormikInput
                    mb={34}
                    key={name}
                    field={name}
                    label={label}
                    type="text"
                    setFieldTouched={setFieldTouched}
                    initialValues={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    errors={errors}
                    dataLocator={dataLocator}
                    placeholder={placeholder}
                />
            ))}
            <Box paddingX={12}>
                <Text text={t('account.profile.gender.title')} type="caption" color="text-secondary" bold mb={16} />
            </Box>
            <S.RadioContainer mb={36} paddingX={14}>
                {/* Todo: use RadioButton from libComponents then issue will be resolved*/}
                {Object.values(GENDERS).map((gender) => (
                    <Radio
                        checked={values.gender === gender}
                        onChange={genderToggleHandler}
                        name="gender"
                        key={gender}
                        label={`account.profile.gender.${gender}`}
                        value={gender}
                    />
                ))}
            </S.RadioContainer>
            <FormikInput
                field="age"
                mb={34}
                label={t('account.profile.age')}
                setFieldTouched={setFieldTouched}
                initialValues={values}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                type="number"
                dataLocator="ageInput"
                placeholder={t('account.profile.age')}
            />
            <Box mb={36}>
                <Box paddingX={12} mb={15}>
                    <Text text={t('account.profile.button.units')} type="caption" color="text-secondary" bold />
                </Box>
                <Box paddingX={10}>
                    <S.Switcher
                        defaultUnit={MEASURE_UNITS[currentUnit]}
                        data={measureUnits}
                        onChange={setUnit}
                        translatedData={measureUnits}
                    />
                </Box>
            </Box>
            <FormikInput
                field="weight"
                mb={34}
                label={t('account.profile.weight')}
                setFieldTouched={setFieldTouched}
                initialValues={values}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                unit={WEIGHT_UNIT[currentUnit]}
                type="number"
                dataLocator="weightInput"
                placeholder={t('account.profile.weight')}
            />
            {currentUnit === UnitTypes.Metric && (
                <FormikInput
                    field="heightMetric"
                    label={t('account.profile.height')}
                    setFieldTouched={setFieldTouched}
                    initialValues={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    errors={errors}
                    type="number"
                    mb={46}
                    unit={TALL_UNIT.metric}
                    dataLocator="heightInput"
                    placeholder={t('account.profile.height')}
                />
            )}
            {currentUnit === UnitTypes.Imperial && (
                <S.ShortInputsWrapper>
                    {weightFields.map((input: weightInput) => (
                        <FormikInput
                            key={input.field}
                            setFieldTouched={setFieldTouched}
                            initialValues={values}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            errors={errors}
                            type="number"
                            mb={26}
                            dataLocator="weightInput"
                            {...input}
                        />
                    ))}
                </S.ShortInputsWrapper>
            )}
            <Button
                onClick={handleSubmit}
                isLoading={isLoading}
                backgroundColor="primary-default"
                disabled={!Object.keys(touched).length || !isEmpty(errors)}
                mb={56}
                text={t('account.profile.button.save')}
            />
        </Box>
    );
}

export default ProfileForm;
