import { useEffect } from 'react';

// helpers
import { getLanguage } from 'api/utils/languageManagment';

// services
import { initFrontChat, shutdownFrontChat } from 'services/FrontChat';

// store
import { useSelector } from 'hooks';
import { selectCurrentUser } from 'store/user/selectors';

function useFrontChat() {
    const language = getLanguage();
    const { email, name } = useSelector(selectCurrentUser);

    useEffect(() => {
        initFrontChat({ name, email, language });

        return () => shutdownFrontChat();
    }, []);
}

export default useFrontChat;
