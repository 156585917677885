import React from 'react';
import { Text } from 'wikr-core-components';

// components
import OutlinedButton from 'components/OutlinedButton';
import OverlineButton from 'components/OverlineButton';
import Modal from '../Modal';

// assets
import warning from 'assets/img/warning.svg';

// types
import { IAlertModal } from '../types';

// styled
import * as S from './styled';

const AlertModal = ({ isOpen, onSubmit, onDiscard, captions, onClose }: IAlertModal) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick>
            <S.Img src={warning} alt="warning" />
            <Text type="h5" center color="text-main" text={captions.title} mb={12} />
            <Text type="medium-text" center color="text-secondary" text={captions.description} mb={32} />

            {onSubmit && captions.submitButton && (
                <OutlinedButton onClick={onSubmit} text={captions.submitButton} mb={20} />
            )}
            {onDiscard && captions.discardButton && (
                <OverlineButton onClick={onDiscard} text={captions.discardButton} mb={4} />
            )}
        </Modal>
    );
};

export default AlertModal;
