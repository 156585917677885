import { all, call, put, takeLatest } from 'redux-saga/effects';

// api
import api from 'api';

// store
import { getDeepLinkError, getDeepLinkSuccess } from './actions';
import { GET_DEEP_LINK_REQUEST } from './actionTypes';

function* getDeepLink() {
    try {
        const deepLink: string = yield call(api.user.deepLinks);

        yield put(getDeepLinkSuccess(deepLink));
    } catch (error: any) {
        yield put(getDeepLinkError(error.error));
    }
}

export default function* watchDeepLink() {
    yield all([takeLatest(GET_DEEP_LINK_REQUEST, getDeepLink)]);
}
