import * as actionTypes from './actionTypes';

// types
import { IGuide } from 'types/guide';

export const fetchGuides = () => {
    return {
        type: actionTypes.FETCH_GUIDES,
    } as const;
};

export const setGuides = (payload: IGuide[]) => {
    return {
        type: actionTypes.SET_GUIDES,
        payload,
    } as const;
};

export const setLoadingState = (payload: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATE,
        payload,
    } as const;
};
