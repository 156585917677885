import { useSelector } from 'hooks/store';

// hooks
import { useAuthentication } from 'hooks';

// theme
import ThemeProvider from './theme/provider';

// services
import useI18next from './services/i18n';

// pages
import Router from './router';

// containers
import NotificationsContainer from 'containers/Notifications';

// store
import { isAuthenticationCompleteSelector } from 'store/auth/selectors';

// components
import { PageLoader } from 'components/PageLoader';
import ErrorBoundary from 'components/ErrorBoundary';

function App() {
    const isComplete = useSelector(isAuthenticationCompleteSelector);
    const { isTranslationsLoaded } = useI18next();
    useAuthentication();

    return (
        <ThemeProvider>
            <NotificationsContainer />
            <ErrorBoundary>
                {isTranslationsLoaded && isComplete ? (
                    <>
                        <Router />
                    </>
                ) : (
                    <PageLoader />
                )}
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default App;
