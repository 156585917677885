import React from 'react';

// components
import { LogoImage } from 'components/Logo';

// styles
import * as S from './styled';

export const PageLoader = () => {
    return (
        <S.Container backgroundColor="primary-default">
            <LogoImage />
        </S.Container>
    );
};
