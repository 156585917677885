import React from 'react';
import { Box } from 'wikr-core-components';

// hooks
import { useSubscriptions, useCancelSubscription } from 'hooks';

// components
import SubscriptionCard from './components/SubscriptionCard';
import SubscriptionHelpButton from './components/SubscriptionHelpButton';
import SubscriptionsSkeleton from './components/SubscriptionsSkeleton';

// types
import { ISubscription } from 'types/subscription';

const SubscriptionsTab = () => {
    const { isLoading, subscriptions } = useSubscriptions();
    const { CancelSubscriptionModal, initSubscriptionCancellation } = useCancelSubscription();

    const displaySkeleton = isLoading && !subscriptions?.length;

    return (
        <Box paddingTop={40} paddingBottom={40}>
            {displaySkeleton && <SubscriptionsSkeleton />}

            {!displaySkeleton &&
                subscriptions.map((subscription: ISubscription) => (
                    <SubscriptionCard
                        key={subscription.id}
                        subscription={subscription}
                        onCancelSubscription={initSubscriptionCancellation}
                    />
                ))}

            <SubscriptionHelpButton />
            <CancelSubscriptionModal />
        </Box>
    );
};

export default SubscriptionsTab;
