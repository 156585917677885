import { Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';

// components
import { Guide } from 'components/Guide';
import GuidesList from './GuidesList';
import GuidesSkeleton from './GuidesSkeleton';

// styled
import * as S from './styled';

// hooks
import { useGuides } from 'hooks';

export const Guides = () => {
    const { t } = useTranslation();
    const { userGuides, purchasedGuides, bonusGuides, isLoading } = useGuides();

    return (
        <S.Container>
            <Text type="h5" center mb={48}>
                <Trans i18nKey="guides.title" />
            </Text>
            <S.GuidesListWrapper>
                {isLoading && <GuidesSkeleton />}

                {Boolean(purchasedGuides.length) && (
                    <GuidesList guides={purchasedGuides} title={t('guides.subtitle.purchased')} />
                )}

                {Boolean(bonusGuides.length) && <GuidesList guides={bonusGuides} title={t('guides.subtitle.bonus')} />}

                {!userGuides.length && !isLoading && <Guide title={t('guides.notFound')} />}
            </S.GuidesListWrapper>
        </S.Container>
    );
};
