import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// appConfig
import appConfig from 'appConfig';

// components
import { Accordion } from 'components/Accordion';

// mock
import ACCORDION_MOCKED_DATA from './mock';

// styles
import * as S from './styled';

export const FAQPage = () => {
    const { t } = useTranslation();

    return (
        <S.Container paddingX={16} paddingTop={36}>
            <Text type="h5" center mb={12} text={t('faq.title')} />
            <Text type="small-text" center mb={24} text={t('faq.subtitle')} />
            <S.List>
                {ACCORDION_MOCKED_DATA.map(({ id, title, content }) => (
                    <S.ListItem mb={16} key={id}>
                        <Accordion title={title} content={content} />
                    </S.ListItem>
                ))}
            </S.List>
            <Text type="medium-text" center mb={4} text={t('faq.help.subTitle')} />
            <Text type="large-text" bold center mb={20} text={t('faq.help.title')} />
            <S.ContactUsLink href={`mailto:${appConfig.base.supportEmail}`}>
                <Text type="medium-text" bold center mb={24} color="on-primary" text={t('basics.contactUs')} />
            </S.ContactUsLink>
        </S.Container>
    );
};
