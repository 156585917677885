import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { StoreButton } from 'wikr-core-components';

export const SkeletonWrapper = styled(Skeleton)<{ isMobile: boolean }>`
    margin-left: ${(props) => !props.isMobile && '10px'};
`;

export const StoreBtn = styled(StoreButton)<{ isMobile: boolean }>`
    width: 151.88px !important;
    height: 45px !important;
    margin-bottom: ${(props) => props.isMobile && '21px !important'};
    display: block;
`;
