import React from 'react';
import { withTheme } from 'styled-components';
import { Theme } from 'wikr-core-components';

const ErrorIcon = ({ theme }: { theme: Theme }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 4.64973L15.3503 3L9.99998 8.35025L4.64973 3L3 4.64973L8.35025 9.99998L3 15.3503L4.64973 17L9.99998 11.6497L15.3502 17L17 15.3503L11.6497 9.99998L17 4.64973Z"
            fill={theme['critical-default']}
        />
    </svg>
);

export default withTheme(ErrorIcon);
