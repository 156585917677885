import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// components
import { AlertModal, CancellationReasonModal, WaitingModal } from 'components/Modal';

// hooks
import { useDispatch } from 'hooks';

// actions
import { discardSubscriptionCancellation, cancelSubscription } from 'store/subscriptions/actions';

export interface CancelCandidate {
    expirationDate: string;
    externalId: string;
    productCode: string;
}

const useCancelSubscription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [cancellationCandidate, setCancelCandidate] = useState<CancelCandidate | null>(null);
    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [sendingCancellation, setSendingStatus] = useState(false);

    const initSubscriptionCancellation = useCallback((candidate: CancelCandidate) => {
        setCancelCandidate(candidate);
    }, []);

    const confirmCancellation = useCallback(() => {
        setCancelConfirmation(true);
    }, []);

    const discardCancellation = useCallback(() => {
        setCancelCandidate(null);
        setCancelConfirmation(false);
        setSendingStatus(false);
    }, []);

    const onCancelSubscription = useCallback(() => {
        setSendingStatus(true);

        if (!cancellationCandidate) return;

        dispatch(
            cancelSubscription({
                externalId: cancellationCandidate.externalId,
                onError: discardCancellation,
                onSuccess: discardCancellation,
                onCancel: discardCancellation,
            })
        );
    }, [cancellationCandidate, discardCancellation]);

    const handleDiscardCancelling = useCallback(() => {
        dispatch(discardSubscriptionCancellation());
    }, []);

    const isConfirmDialogShown = Boolean(!cancelConfirmation && cancellationCandidate);
    const isSurveyDialogShown = Boolean(cancelConfirmation && cancellationCandidate);

    const CancelSubscriptionModal = useMemo(
        () => () =>
            (
                <>
                    <AlertModal
                        isOpen={!sendingCancellation && isConfirmDialogShown}
                        onClose={discardCancellation}
                        onDiscard={discardCancellation}
                        onSubmit={confirmCancellation}
                        captions={{
                            title: t('subscription.cancelDialog.title'),
                            description: t('subscription.cancelDialog.description', {
                                date: cancellationCandidate?.expirationDate,
                            }),
                            submitButton: t('subscription.text.cancel'),
                            discardButton: t('subscription.cancelDialog.discard'),
                        }}
                    />

                    <CancellationReasonModal
                        isOpen={!sendingCancellation && isSurveyDialogShown}
                        onDiscard={discardCancellation}
                        onClose={discardCancellation}
                        onSubmit={onCancelSubscription}
                    />

                    <WaitingModal
                        captions={{
                            title: t('subscription.cancelDialog.waiting'),
                            discardButton: t('basics.back'),
                        }}
                        isOpen={sendingCancellation}
                        onDiscard={handleDiscardCancelling}
                        onClose={discardCancellation}
                    />
                </>
            ),
        [
            isConfirmDialogShown,
            isSurveyDialogShown,
            cancellationCandidate,
            confirmCancellation,
            sendingCancellation,
            handleDiscardCancelling,
        ]
    );

    return {
        CancelSubscriptionModal,
        initSubscriptionCancellation,
    };
};

export default useCancelSubscription;
