// types
import { InferValueTypes } from 'types/commonTypes';

// store
import * as action from './actions';
import * as actionTypes from './actionTypes';

const initialState = {
    country: null,
    date_of_birth: null,
    email: null,
    fitness_level: null,
    gender: null,
    goal: null,
    height: null,
    is_paid: null,
    is_password: null,
    language: null,
    last_name: null,
    localization: null,
    name: null,
    product_list: null,
    target_bodytype: null,
    target_weight: null,
    target_zone: null,
    units: null,
    user_id: null,
    weight: null,
    token: null,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const userReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.RESET_USER_DATA:
            return { ...initialState };

        case actionTypes.UPDATE_USER_SUCCESS:
        case actionTypes.GET_CURRENT_USER_SUCCESS:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};

export default userReducer;
