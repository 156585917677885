import { useEffect } from 'react';

// hooks
import { useDispatch, useSelector } from 'hooks';

// store
import { fetchSubscriptions } from 'store/subscriptions/actions';

const useSubscriptions = () => {
    const dispatch = useDispatch();
    const { subscriptions, isLoading } = useSelector((state) => state.subscriptions);

    useEffect(() => {
        dispatch(fetchSubscriptions());
    }, []);

    return {
        subscriptions,
        isLoading,
    };
};

export default useSubscriptions;
