import { Box, Button } from 'wikr-core-components';
import styled from 'styled-components';

// theme
import { color } from 'theme/selectors';

export const SmallButton = styled(Button)`
    padding: 8px 12px;
    min-height: unset;
    min-width: unset;
    width: auto;

    * {
        letter-spacing: unset;
        text-transform: unset;
        font-size: 12px;
        line-height: 16px;
    }
`;

// todo: rm after padding issue in lib will be fixed
export const StyledInput = styled(Box)`
    > * {
        margin-bottom: 20px;
    }
`;
