import { uaParser } from 'wikr-core-analytics';

// interfaces
import { Event } from '@sentry/types';
import { Extras, errorType, Tags, IError } from './interfaces';

// constants
import { SENTRY_AXIOS } from './constants';

// helpers
import { objectEntriesPolyfill } from 'helpers/polyfills';

const IOS = 'iOS';
const ANDROID = 'Android';
const CHROME = 'Chrome';
const IOS_MIN_VERSION = 14.3;
const ANDROID_MIN_VERSION = 7;
const CHROME_MIN_VERSION = 59;
const FB_BOT_WIDTH_SIZE = 2000;
const FB_BOT_HEIGHT_SIZE = 2000;

export const getCustomTags = (error: IError, type: errorType, extras?: Extras): Tags => {
    const customTags: Record<string, string> = {};
    if (type === SENTRY_AXIOS) {
        customTags.API_ERROR_CODE = extras?.status as string;
    }

    return objectEntriesPolyfill(customTags);
};

export const checkIsOldDevices = (event: Event) => {
    const UAParser = new uaParser();
    const userAgent = UAParser.setUA(event?.extra?.agent as string);

    const browser = userAgent?.getBrowser();
    const deviceOS = userAgent?.getOS();

    const parsedOSVersion = parseFloat(deviceOS?.version || '');
    const parsedBrowserVersion = parseFloat(browser?.major || '');

    return (
        (deviceOS?.name === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
        (deviceOS?.name === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION) ||
        (browser?.name === CHROME && parsedBrowserVersion < CHROME_MIN_VERSION)
    );
};

export const checkFbBots = (): boolean => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === FB_BOT_WIDTH_SIZE && windowHeight === FB_BOT_HEIGHT_SIZE;
};
