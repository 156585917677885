import styled from 'styled-components';
import { Box } from 'wikr-core-components';

// theme
import mediaQueries from 'theme/mediaQueries';

export const AuthContainer = styled.div`
    width: 100vw;
    min-height: calc(100vh - 56px);
    display: flex;
    flex-direction: row;
    align-items: start;

    ${mediaQueries.tablet} {
        align-items: center;
    }

    ${mediaQueries.tabletLandscape} {
        align-items: stretch;
    }
`;

export const AuthForm = styled.div`
    position: relative;
    width: ${({ theme }) => `calc(400px + (${theme['default-indent']}px * 2))`};
    margin: 0 auto;
    padding: 32px 0;

    ${mediaQueries.tabletLandscape} {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const AuthImage = styled(Box)`
    display: none;
    max-width: 50%;

    * {
        height: 100%;
        object-fit: cover;
    }

    ${mediaQueries.tabletLandscape} {
        display: block;
    }
`;
