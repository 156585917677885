import styled from 'styled-components';
import { color } from 'theme/selectors';
import { Link } from 'react-router-dom';

// image
import bg from './img/bg.webp';

export const HelpButton = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 27px;
    padding: 28px 16px;
    border-radius: 12px;
    background: center / cover no-repeat url(${bg}), ${color('surface-secondary')};

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const FaqLink = styled(Link)`
    display: flex;
    align-items: center;
    height: 32px;
    padding: 4px 12px;
    background: ${color('primary-default')};
    border-radius: 10px;
    z-index: 10;
`;
