import React from 'react';
import { Text } from 'wikr-core-components';

// styled
import { Button } from './styled';

const OverlineButton = ({ text, onClick, mb }: { text: string; onClick: () => void; mb?: string | number }) => (
    <Button onClick={onClick} mb={mb}>
        <Text type="overline" text={text} />
    </Button>
);

export default OverlineButton;
