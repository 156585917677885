export enum linkType {
    Primary = 'primary',
    Secondary = 'secondary',
}

export interface SidebarLinkProps {
    to: string;
    title: string;
    dataLocator?: string;
    onClick?: (to?: string) => void;
    color?: string;
    type: linkType;
    target?: string;
    external?: boolean;
}

export interface SidebarLinkButtonProps {
    isActive: boolean;
    isPrimary: boolean;
    title: string;
}
