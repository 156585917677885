import React from 'react';

// components
import Skeleton from 'components/Skeleton';

// styled
import { SkeletonWrapper } from './styled';

const SubscriptionsSkeleton = () => (
    <SkeletonWrapper>
        <Skeleton height={134} mb={20} />
        <Skeleton height={134} mb={20} />
    </SkeletonWrapper>
);

export default SubscriptionsSkeleton;
