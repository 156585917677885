import { getToFixedNumber } from './number';

const LBS_KG_COEFFICIENT = 2.2046;
const CM_INCH_COEFFICIENT = 2.54;
const FEET_INCH_COEFFICIENT = 12;

export const fromLbsToKg = (lbs: number): number => {
    return lbs / LBS_KG_COEFFICIENT;
};

export const fromKgToLbs = (kg: number): number => {
    return Math.round(kg * LBS_KG_COEFFICIENT);
};

export const fromInToCm = (inch: number): number => {
    return inch / 0.3937;
};

export const fromFeetAndInchesToCentimeters = (ft: number, inch: number) => {
    const feetInCM = ft * 30.48;
    const inchesInCM = inch * 2.54;

    return getToFixedNumber(feetInCM + inchesInCM);
};

export const cmToFeetAndInch = (cm: number) => {
    const length = cm / CM_INCH_COEFFICIENT;
    const feet = Math.round(Math.trunc(length / FEET_INCH_COEFFICIENT) * 100) / 100;
    const inch = Math.round((length - FEET_INCH_COEFFICIENT * feet) * 100) / 100;

    return {
        feet,
        inch,
    };
};
