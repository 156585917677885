import Sentry from './Sentry';
import config from 'config';

const tracesSampleRate = config.ENV === 'prod' ? 0.05 : 1;

export default new Sentry({
    sentryDSN: config.SENTRY_DSN,
    env: config.ENV,
    tracesSampleRate,
    autoSessionTracking: true,
});
