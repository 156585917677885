import { useSelector } from 'hooks/store';
import { Routes, Route, Navigate } from 'react-router-dom';

// router
import PrivateRoute from 'router/PrivateRoute';
import AuthLayout from 'router/layouts/AuthLayout';
import CabinetLayout from 'router/layouts/CabinetLayout';
import { PUBLIC, PRIVATE } from 'router/routes';

// store
import { isAuthenticatedSelector } from 'store/auth/selectors';

const Router = () => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    return (
        <Routes>
            {Object.values(PUBLIC).map(({ path, titleKey, component: Component }: any) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <AuthLayout titleKey={titleKey}>
                            <Component />
                        </AuthLayout>
                    }
                />
            ))}

            {Object.values(PRIVATE).map(({ path, titleKey, component: Component }: any) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <CabinetLayout titleKey={titleKey}>
                            <PrivateRoute component={Component} />
                        </CabinetLayout>
                    }
                />
            ))}

            <Route path="*" element={<Navigate to={isAuthenticated ? PRIVATE.MAIN.path : PUBLIC.LOGIN.path} />} />
        </Routes>
    );
};

export default Router;
