import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input } from 'wikr-core-components';

// types
import { FormikInputType, InputType } from 'types/ui';

// constants
import { measure } from 'constants/units';

// styled
import * as S from './styled';

const { TALL_UNIT } = measure;

const FormikInput = ({
    values,
    errors,
    initialValues,
    setFieldTouched,
    setFieldValue,
    touched,
    field,
    type = 'text',
    label,
    unit,
    className,
    leftIcon,
    innerRef,
    rightIcon,
    dataLocator,
    placeholder,
    theme,
    mb,
}: FormikInputType) => {
    const [isFocus, setIsFocus] = useState(false);

    const { t } = useTranslation();

    const errorStatus = !isFocus && Boolean(errors[field] && touched[field]);

    const withoutInchLabel = unit === TALL_UNIT.imperial.in ? '' : t(label || field);

    return (
        <S.StyledWrapper mb={mb}>
            <Input
                label={withoutInchLabel}
                name={field}
                type={type as InputType}
                value={`${values[field]}`}
                unit={unit}
                placeholder={placeholder && t(placeholder)}
                errorMessage={t(errors[field] || '')}
                initialValue={`${initialValues[field]}`}
                errorStatus={errorStatus}
                successMessage={t('validation.perfect')}
                onBlur={() => {
                    setIsFocus(false);
                    setFieldTouched(field);
                }}
                successStatus={!errors[field] && Boolean(touched[field])}
                onClear={() => setFieldValue(field, '')}
                className={className}
                onFocus={() => setIsFocus(true)}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                ref={innerRef}
                onChange={(e) => setFieldValue(field, e.target.value)}
                dataLocator={dataLocator}
                theme={theme}
                isFocus={isFocus}
            />
        </S.StyledWrapper>
    );
};

export default withTheme(FormikInput);
