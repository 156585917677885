import { delay, put, take, takeLatest, fork, cancel, cancelled, all, call } from 'redux-saga/effects';
import { Task } from '@redux-saga/types';
import { CancelledEffect } from '@redux-saga/core/effects';

// api
import api from 'api';

// actions
import { setSubscriptions, setSubscriptionsFetchingStatus, cancelSubscription, fetchSubscriptions } from './actions';
import { notifyError, notifySuccess, notify } from '../notifications/actions';

// types
import { CANCEL_SUBSCRIPTION, DISCARD_SUBSCRIPTION_CANCELLATION, FETCH_USER_SUBSCRIPTIONS } from './actionTypes';
import { ICancelSubscription } from './types';
import { ISubscription } from 'types/subscription';
import { SubscriptionCancelResponse } from 'api/types/response';

// helpers
import { sortFullAccessFirst } from './helpers';

function* getSubscription() {
    try {
        const response: ISubscription[] = yield call(api.subscriptions.getSubscriptions);

        yield put(setSubscriptions(sortFullAccessFirst(response)));
        yield put(setSubscriptionsFetchingStatus(false));
    } catch (error) {
        notifyError('getSubscription error');
    }
}

function* makeSubscriptionCancelling({ payload }: ReturnType<typeof cancelSubscription>) {
    try {
        const workerTask: Task = yield fork(callUnsubscribe, payload);
        yield take(DISCARD_SUBSCRIPTION_CANCELLATION);
        yield cancel(workerTask);
    } catch (error) {
        payload.onError();
    }
}

function* callUnsubscribe(payload: ICancelSubscription) {
    try {
        yield delay(3000);

        const response: SubscriptionCancelResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: payload.externalId,
        });

        if (!response.result) {
            throw new Error('Subscription is not cancelled');
        }

        yield put(fetchSubscriptions());
        yield put(notifySuccess('subscription.cancellation.response.success'));
        payload.onSuccess();
    } catch (error) {
        yield put(notifyError('subscription.cancellation.response.error'));
        payload.onError();
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();
        if (isCancelled) {
            yield put(notify('subscription.cancellation.response.abort'));
            payload.onCancel();
        }
    }
}

export default function* watchSubscriptions() {
    yield all([
        takeLatest(FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
    ]);
}
