import { FC } from 'react';

// components
import { Circle } from './Circle';

// styled
import * as S from './styled';

import { StepProgressProps } from './types';

export const StepProgress: FC<StepProgressProps> = ({ data }) => {
    return (
        // todo: also needs to be refactored for better responsibility
        <S.Container>
            {data.map(({ id, isFinished, isCurrentStep, label }, index) => (
                <S.Wrapper key={id}>
                    <Circle id={id} isFinished={isFinished} isCurrentStep={isCurrentStep} label={label} />
                    {index !== data.length - 1 && <S.Line isFinished={isFinished} />}
                </S.Wrapper>
            ))}
        </S.Container>
    );
};
