import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'wikr-core-components';

// components
import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import PriceRow from './PriceRow';

// styled
import * as S from './styled';

// types
import { ISubscriptionCardCommonProps } from '../types';

const ExpiredSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    expirationDate,
    subscriptionType,
}: ISubscriptionCardCommonProps) => {
    const { t } = useTranslation();

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} isExpired />
            <PriceRow
                period={subscriptionPeriod}
                subscriptionType={subscriptionType}
                price={price}
                color="text-secondary"
            />
            <S.DateInfo>
                <Text type="caption" color="text-secondary-subdued" text={t('subscription.text.expiredOn')} />
                <Text type="caption" color="text-secondary-subdued" text={expirationDate} />
            </S.DateInfo>
        </SubscriptionCardWrapper>
    );
};

export default ExpiredSubscription;
