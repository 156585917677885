import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Text } from 'wikr-core-components';

// images
import checkMarkSvg from 'assets/img/check-mark.svg';

// styled
import * as S from './styled';

interface CirceProps {
    isFinished: boolean;
    isCurrentStep: boolean;
    label: string;
    id: number;
}

export const Circle: FC<CirceProps> = ({ isFinished, isCurrentStep, label, id }) => {
    return (
        <S.CircleContainer>
            <S.Circle isFinished={isFinished} isCurrentStep={isCurrentStep}>
                {isFinished ? <img alt="check-mark" src={checkMarkSvg} /> : <Text type="small-text">{id}</Text>}
            </S.Circle>
            <S.LabelContainer>
                <Text type="caption" center>
                    <Trans i18nKey={label} />
                </Text>
            </S.LabelContainer>
        </S.CircleContainer>
    );
};
