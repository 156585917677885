import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Text, Button } from 'wikr-core-components';

// helpers
import { isEmpty } from 'helpers/dataStructuresUtils';

// store
import { updateUserEmail } from 'store/user/actions';

// hooks
import { useDispatch } from 'hooks';

// validation
import { ForgotPasswordSchema } from './validationSchema';

// styles
import * as S from './styled';

// components
import FormikInput from 'components/FormikInput';

// images
import emailIcon from 'assets/img/icons/emailIcon.webp';

const initialValues = {
    email: '',
};

type FormValue = {
    email: string;
};

export const ChangeEmail = () => {
    const [isLoading, setLoadingState] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { errors, dirty, values, setFieldTouched, setFieldValue, handleSubmit, touched } = useFormik({
        initialValues,
        validationSchema: ForgotPasswordSchema,
        onSubmit: (payload: FormValue) => {
            setLoadingState(true);
            dispatch(
                updateUserEmail({
                    email: payload.email,
                    onSuccess: () => {
                        navigate(-1);
                    },
                    onError: () => {
                        setLoadingState(false);
                    },
                })
            );
        },
    });

    return (
        <S.Container paddingTop={88} paddingX={16} paddingBottom={32}>
            <Text center mb={20} type="h5" text={t('change.email.title')} />
            <form>
                <div>
                    <FormikInput
                        type="email"
                        field="email"
                        label="change.email.newEmail"
                        setFieldTouched={setFieldTouched}
                        initialValues={values}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        mb={44}
                        leftIcon={emailIcon}
                        dataLocator="emailInput"
                        placeholder={t('change.email.newEmail')}
                    />
                </div>
                <Button
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    backgroundColor="primary-default"
                    disabled={!dirty || !isEmpty(errors)}
                    mb={24}
                    text={t('account.profile.button.save')}
                />
            </form>
        </S.Container>
    );
};
