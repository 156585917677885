// store
import * as actionTypes from './actionTypes';
import * as action from './actions';

// types
import { InferValueTypes } from 'types/commonTypes';
import { ISubscription } from 'types/subscription';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

interface SubscriptionsState {
    subscriptions: ISubscription[];
    isLoading: boolean;
}

const initialState = {
    subscriptions: [],
    isLoading: true,
};

const subscriptionsReducer = (state: SubscriptionsState = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_FETCHING_STATUS:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_USER_SUBSCRIPTIONS:
            return { isLoading: false, subscriptions: action.payload };

        default:
            return state;
    }
};
export default subscriptionsReducer;
