import { FC } from 'react';
import { Text } from 'wikr-core-components';

// styled
import * as S from './styled';
import { Download } from 'components/Icon';

interface GuideProps {
    img?: string;
    link?: string;
    title: string;
}

export const Guide: FC<GuideProps> = ({ title, img, link }) => (
    <S.Container>
        {img && <S.GuideImg alt="guide-img" src={img} />}
        <S.GuideTitleContainer>
            <Text center={!img} type="medium-text" text={title} />
        </S.GuideTitleContainer>
        {link && (
            <S.DownloadGuideLink href={link} target="_blank">
                <Download />
            </S.DownloadGuideLink>
        )}
    </S.Container>
);
