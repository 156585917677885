import { Box, Image } from 'wikr-core-components';
import styled from 'styled-components';

import mediaQueries from 'theme/mediaQueries';

// theme
import { color } from 'theme/selectors';

export const Container = styled(Box)`
    width: 100%;
    margin: auto;
    max-width: 900px;

    ${mediaQueries.laptop} {
        padding-bottom: 0 !important;
    }
`;

export const FlexWrapper = styled(Box)`
    display: flex;
`;

export const TabletWrapper = styled(Box)`
    display: none;

    ${mediaQueries.tabletLandscape} {
        display: block;
    }
`;

export const MobileWrapper = styled(Box)`
    display: block;

    ${mediaQueries.tabletLandscape} {
        display: none;
    }
`;

export const StepContainer = styled(FlexWrapper)`
    width: 100%;
    flex-direction: column;
    gap: 30px;
    align-items: center;

    ${mediaQueries.tabletLandscape} {
        flex-direction: row;
        gap: 16px;
        height: 502px;
    }

    > * {
        width: 100%;
        height: 100%;
        max-width: 400px;

        ${mediaQueries.tabletLandscape} {
            max-width: 50%;
        }
    }
`;

export const ScanQrCodeWrapper = styled(Box)`
    width: 200px;
    height: 200px;

    > * {
        width: 100% !important;
        height: 100% !important;
    }
`;

export const DownloadWrapper = styled(Box)`
    max-width: 311px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Video = styled(Image)`
    border: 6px solid ${color('border-default')};
    border-radius: 20px;
`;

export const Footer = styled(FlexWrapper)`
    align-items: center;
    justify-content: space-between;
`;
