import { call, takeLatest, all, put } from 'redux-saga/effects';

// api
import api from 'api';
import { removeToken } from 'api/utils/tokenManagement';

// types
import { UserStore } from 'types/store/userStore';

// store
import {
    signInRequest,
    signInSuccess,
    signInError,
    authentication,
    setAuthenticationStatus,
    authenticationBySignature,
    setAuthRedirectUrl,
} from './actions';
import { getCurrentUserSuccess, resetUserData } from '../user/actions';
import { notifyError } from '../notifications/actions';

// actionTypes
import { SIGN_IN_REQUEST, AUTHENTICATE, AUTHENTICATE_BY_SIGNATURE, LOG_OUT } from './actionTypes';

function* signIn({ payload }: ReturnType<typeof signInRequest>) {
    const { userData, onSuccess } = payload;

    try {
        const userDataResponse: UserStore = yield call(api.user.signIn, userData);

        yield put(getCurrentUserSuccess(userDataResponse));
        yield put(setAuthenticationStatus(true));
        yield put(signInSuccess(userDataResponse));

        onSuccess && onSuccess(userDataResponse);
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));

        if (error?.error) yield put(signInError(error.error));
    }
}

function* authenticate({ payload }: ReturnType<typeof authentication>) {
    const { onSuccess, onError } = payload;
    try {
        const userData: UserStore = yield call(api.user.getUser);

        yield put(getCurrentUserSuccess(userData));
        yield put(setAuthenticationStatus(true));

        onSuccess && onSuccess();

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(setAuthenticationStatus(false));

        onError && onError();
    }
}

function* authenticateBySignature({ payload }: ReturnType<typeof authenticationBySignature>) {
    const { uId, signature, onSuccess, onError } = payload;
    try {
        const userData: UserStore = yield call(api.user.getUserBySignature, { uId, signature });

        yield put(getCurrentUserSuccess(userData));
        yield put(setAuthenticationStatus(true));

        onSuccess && onSuccess();

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError('message.error.somethingWentWrong'));
        yield put(setAuthenticationStatus(false));

        onError && onError();
    }
}

function* logout() {
    removeToken();
    yield put(setAuthenticationStatus(false));
    yield put(resetUserData());
}

export default function* watchAuth() {
    yield all([
        takeLatest(LOG_OUT, logout),
        takeLatest(SIGN_IN_REQUEST, signIn),
        takeLatest(AUTHENTICATE, authenticate),
        takeLatest(AUTHENTICATE_BY_SIGNATURE, authenticateBySignature),
    ]);
}
